import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const amtClearRouter = {
  path: "/amt-clearing",
  component: Layout,
  redirect: "/amt-clearing/index",
  meta: {
    icon: "amt-clearing",
    title: $t("资金管理"),
    rank: 9,
    authority: ["sttl.report.amount"]
  },
  children: [
    {
      path: "/amt-clearing/index",
      name: "AmtAClearing",
      component: () => import("/@/views/amt-clearing/report/index.vue"),
      meta: {
        title: $t("资金结算报表"),
        authority: ["sttl.report.amount"],
        showParent: true,
        keepAlive: true
      }
    }
  ]
};

export default amtClearRouter;
