import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const clearRouter = {
  path: "/clearing",
  component: Layout,
  redirect: "/clearing/detail",
  meta: {
    icon: "log-admin",
    title: $t("结算管理"),
    rank: 9,
    authority: ["sttl.report", "sttl.total"]
  },
  children: [
    {
      path: "/clearing/detail",
      name: "ClearDetail",
      component: () => import("/@/views/clearing/detail.vue"),
      meta: {
        title: $t("结算明细"),
        authority: ["sttl.report"],
        showParent: true,
        keepAlive: true
      }
    },
    {
      path: "/clearing/total",
      name: "ClearTotal",
      component: () => import("/@/views/clearing/collect.vue"),
      meta: {
        title: $t("结算汇总"),
        authority: ["sttl.total"],
        showParent: true,
        keepAlive: true
      }
    }
  ]
};

export default clearRouter;
