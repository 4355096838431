import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const fileAutoRouter = {
  path: "/file-auto",
  component: Layout,
  redirect: "/file-auto/admin",
  meta: {
    icon: "fileAuto-admin",
    title: $t("文件自动化"),
    rank: 33,
    authority: ["op.file.config", "op.file.row"]
  },
  children: [
    {
      path: "/file-auto/config",
      name: "fileConfig",
      component: () => import("/@/views/file-auto/file-config/index.vue"),
      meta: {
        title: $t("文件配置"),
        showParent: true,
        authority: ["op.file.config"]
      },
      children: [
        {
          path: "/file-auto/config/add",
          name: "fileConfigAdd",
          component: () => import("/@/views/file-auto/file-config/add.vue"),
          meta: {
            title: $t("新增"),
            keepAlive: true,
            showLink: false
          }
        }
      ]
    },
    {
      path: "/fifile-autole/chase",
      name: "fileChase",
      component: () => import("/@/views/file-auto/file-chase/index.vue"),
      meta: {
        title: $t("文件追踪"),
        showParent: true,
        authority: ["op.file.row"]
      }
    }
  ]
};

export default fileAutoRouter;
