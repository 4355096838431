import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const accountCenterRouter = {
  path: "/account-center",
  component: Layout,
  redirect: "/account-center/user",
  meta: {
    icon: "account-center",
    title: $t("accountCnter.pageTitle"),
    rank: 12,
    authority: ["user", "role"]
  },
  children: [
    {
      path: "/account-center/user",
      name: "AccountCenterUser",
      component: () => import("/@/views/account-center/user-new/index.vue"),
      meta: {
        title: $t("accountCnter.userTitle"),
        keepAlive: true,
        showParent: true,
        authority: ["user"]
      },
      children: [
        {
          path: "/account-center/user/add",
          name: "AccountCenterUserAdd",
          component: () => import("/@/views/account-center/user-new/Add.vue"),
          meta: {
            title: $t("新增用户"),
            keepAlive: true,
            showLink: false,
            authority: ["user"]
          }
        },
        {
          path: "/account-center/user/edit",
          name: "AccountCenterUserEdit",
          component: () => import("/@/views/account-center/user-new/edit.vue"),
          meta: {
            title: $t("编辑用户"),
            keepAlive: true,
            showLink: false
          }
        }
      ]
    },
    {
      path: "/account-center/role",
      name: "AccountCenterRole",
      component: () => import("/@/views/account-center/role-new/index.vue"),
      meta: {
        title: $t("accountCnter.roleTitle"),
        showParent: true,
        authority: ["role"]
      },
      children: [
        {
          path: "/account-center/role/check",
          name: "AccountCenterRoleCheck",
          component: () => import("/@/views/account-center/role-new/Check.vue"),
          meta: {
            title: $t("accountCnter.check"),
            keepAlive: true,
            showLink: false,
            authority: ["role"]
          }
        },
        {
          path: "/account-center/role/add",
          name: "AccountCenterRoleAdd",
          component: () => import("/@/views/account-center/role-new/Edit.vue"),
          meta: {
            title: $t("新增角色"),
            keepAlive: true,
            showLink: false
          }
        },
        {
          path: "/account-center/role/edit",
          name: "AccountCenterRoleEdit",
          component: () => import("/@/views/account-center/role-new/Edit.vue"),
          meta: {
            title: $t("编辑角色"),
            keepAlive: true,
            showLink: false
          }
        }
      ]
    }
  ]
};

export default accountCenterRouter;
