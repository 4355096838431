import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const tagMgrRouter = {
  path: "/tag-mgr",
  component: Layout,
  redirect: "/tag-mgr/tag-mgr",
  meta: {
    icon: "tag-mgr",
    title: $t("menus.tagMgr"),
    rank: 10,
    authority: ["label"]
  },
  children: [
    {
      path: "/tag-mgr/tag-mgr",
      name: "TagMgr",
      component: () => import("/@/views/tag-mgr/index.vue"),
      meta: {
        title: $t("menus.tagMgr"),
        keepAlive: true,
        showLink: true
      }
    }
  ]
};

export default tagMgrRouter;
